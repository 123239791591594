<template>

  <div>
    <v-row>
      <v-col cols="8" sm="12">
        <breadcrumbs :items="dataBreadcrumbs" />
      </v-col>
      <v-col cols="4" sm="12" align="right">
      </v-col>
    </v-row>
    <v-card-title>Agence / Point relais
      <v-btn
          color="primary"
          dark
          class="mb-2 float-right"
          @click="dialog = true"
      >
        <v-icon small>
          mdi-plus
        </v-icon>
        Nouvelle Agence
      </v-btn>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer ?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeDelete">Annuler</v-btn>
            <v-btn color="success" text @click="deleteItemConfirm">Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
     </v-card-title>
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ formTitle }}</span>
        </v-card-title>

        <v-form @submit.prevent="save">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col lg="12">
                  <v-select
                      :items="countries"
                      item-value="iso"
                      @change="getCities"
                      item-text="name"
                      outlined
                      dense
                      label="Pays" v-model="editedItem['countryiso']">
                  </v-select>
                </v-col>
                <v-col lg="12" v-if="editedItem['countryiso']">

                  <v-autocomplete
                      v-model="editedItem.cityname"
                      :items="cities"
                      :search-input.sync="searchcity"
                      :loading="isDestinationLoading"
                      outlined
                      dense
                      item-value="name"
                      item-text="name"
                      chips
                      small-chips
                      label="Selectionner un ou plusieurs ville de départ"
                  ></v-autocomplete>

                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="editedItem.name"
                      label="Nom de l'agence (Point relais)"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                >
                  <v-text-field
                      v-model="editedItem.address"
                      label="Adresse"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                >
                  <v-text-field
                      v-model="editedItem.contact"
                      label="Contact"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="error"
                text
                @click="close"
            >
              Fermer
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                dark
                type="submit"
            >
              <v-icon
                  left
                  dark
              >
                mdi-content-save-check
              </v-icon>
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-row>
        <v-col lg="6">
          <v-text-field v-model="searchQuery" label="Filtre"></v-text-field>
        </v-col>
        <!-- v-col lg="2">
          <v-select
              label="Statut"
              v-model="search.status"
              :items="statues"
              item-value="id"
              item-text="label"
              @change="fetchItems"
          ></v-select>
        </v-col -->
        <v-col lg="3">
          <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="interval.date1"
                  label="Du"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="interval.date1"
                @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col lg="3">
          <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="interval.date2"
                  label="Au"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="interval.date2"
                @input="menu3 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

      </v-row>

      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">

      </div>
    </v-card-text>
    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="agencyll.listEntity"
        class="elevation-0"
        :page.sync="agencyll.current_page"
        :items-per-page="agencyll.per_page"
        :sort-by="['id']"
        @page-count="pageCount = $event"
        hide-default-footer
        :sort-desc="true"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
            right
            color="warning"
            dark
            class="mx-1 text-right"
            @click="editItem(item)"
        >
          <v-icon
              small
          >
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
            right
            color="error"
            dark
            class="mx-1 text-right"
            @click="deleteItem(item)"

        >
          <v-icon
              small
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-card-text class="pt-2">
      <v-row>
        <v-col
            lg="2"
            cols="3"
        >

          <v-select
              v-model="agencyll.per_page"
              label="Lignes par page:"
              :items="[10,20,30,50,80,100, 'Toutes']"
              hide-details
              @change="fetchItems()"
          ></v-select>

        </v-col>

        <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
        >
          <v-pagination
              v-model="agencyll.current_page"
              total-visible="6"
              :length="parseInt(agencyll.pagination )"
              @input="fetchItems()"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>

</template>

<script>
import {Drequest} from "@/plugins/Drequest";
import breadcrumbs from "../../components/ui/breadcrumbs";
const moonLanding = new Date();

export default {
  name: "relay-point",
  data: () => ({
    dataBreadcrumbs: [],
    interval: {
      date1: moonLanding.getFullYear() + "-01-01",
      date2: moonLanding.getFullYear() + "-12-31"
    },
    searchQuery: "",
    menu2: false,
    menu3: false,
    transporter: {},
    countries: [],
    agencyll: {},
    loading: false,
    dialog: false,
    dialogDelete: false,
    isDestinationLoading: false,
    headers: [
      {
        text: 'Pays',
        align: 'start',
        sortable: false,
        value: 'country.name',
      },
      {
        text: 'Ville',
        align: 'start',
        sortable: false,
        value: 'cityname',
      },
      {
        text: 'Nom',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {text: 'Contact', value: 'contact'},
      {text: 'Adresse', value: 'address'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    items: [],
    cities: [],
    searchcity: "",
    editedIndex: -1,
    editedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
  }),
  components: {
    breadcrumbs
  },
  props: ["user","nextStep"],
  methods: {
    initialize(id) {
      this.loading = true;
      Drequest.api("lazyloading.agency?dfilters=on&transporter.id:eq=" + id)
          .get((response) => {
            this.loading = false;
            this.agencyll = response
            this.items = this.agencyll.listEntity
          })
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.getCities()
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      Drequest.api("delete.agency?id=" + this.editedItem.id).raw((response) => {
        console.log(response);

        this.items.splice(this.editedIndex, 1)
        this.closeDelete()

      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.loading = true;
      if (this.editedIndex > -1) {
        Drequest.api("update.agency?id=" + this.editedItem.id).data({agency: this.editedItem}).raw((response) => {
          console.log(response);
          this.initialize();
          this.loading = false;
          // this.items[this.editedIndex] = response.agency
          this.close()
        })
      } else {
        this.editedItem['transporter.id'] = this.transporter.id;
        Drequest.api("create.agency").data({agency: this.editedItem}).raw((response) => {
          console.log(response);
          this.loading = false;
          this.items.push(response.agency);
          this.close()
        })
      }
    },

    getCities() {
      this.isDestinationLoading = true
      Drequest.api("city.fetch?country_code=" + this.editedItem['countryiso'])
          .get((response) => {
                console.log(response)
                this.cities = response.cities;
                this.isDestinationLoading = false
              }
          )
    },
    getCitiesByName(name) {
      if (!name)
        return 1;

      if (name.length < 2 || this.isDestinationLoading)
        return 1;

      this.isDestinationLoading = true
      Drequest.api("city.fetch?name=" + name + "&country_code=" + this.editedItem['countryiso'])
          .get((response) => {
                console.log(response)
                this.cities = [...this.cities, ...response.cities];
                this.isDestinationLoading = false
              }
          )

    },
  },
  beforeMount() {
    this.dataBreadcrumbs = [
      {
        text: 'Accueil',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Gestion Point de Relais',
        disabled: true,
        href: '/s',
      }
    ];
    this.transporter = this.$store.state.transporter;
    this.initialize(this.transporter.id);
    Drequest.api("transporter.detail?id=" + this.transporter.id)
        .get((response) => {
          console.log(response);
          this.transporter = response.transporter;
          this.countries = response.countries
        })
  },
  watch: {
    searchcity(val) {
      console.log(val)
      this.getCitiesByName(val)
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouvelle Agence' : 'Editer une Agence'
    },
  },
  mounted() {
    //this.tab = this.items[0];
  }
}
</script>
<style scoped>

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  width: auto;
  font-weight: 600;
  padding: 10px;
  color: white;
}

.interval-relaypoint-table {
  margin: 0 5%;
}

.v-card__title {
  display: revert !important;
}
</style>
